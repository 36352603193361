<template>
    <div class="container mx-auto py-24">
        <h1 class="font-bold text-6xl text-blue-900 leading-none">
            {{$t('company.heading')}}
        </h1>
        
        <h2 
            class="h2 text-blue-900 my-4" 
            v-html="$t('company.intro')" 
        />
        
        <div class="grid grid-cols-2 gap-16 my-12">
            <div class="">
                <div class="max-w-lg">
                    <p v-html="$t('company.story')" />
                    
                </div>
            </div>
            
            <div>
                <div class="flex flex-col space-y-4 mb-12">
                    <h3 class="h3 font-bold text-blue-900">Contact CloudCast</h3>
                    
                    <div class="">
                        <h4 class="h4 font-bold text-blue-900">Address</h4>
                        <p class="">
                            <em>CloudCast AS</em>
                            <br>
                            Org nr: 928 234 126
                            <br>
                            Edvard Griegs allé 5
                            <br>
                            0479 Oslo
                            <br>
                            Norway
                        </p>
                    </div>
                    
                    <div class="">
                        <h4 class="h4 font-bold text-blue-900">Telefon</h4>
                        <p class="">
                            +47 90 84 30 43
                        </p>
                    </div>
                    
                    <div class="">
                        <h4 class="h4 font-bold text-blue-900">Email</h4>
                        <p class="">
                            <a class="link" href="mailto:hello@getcloudcast.com">hello@getcloudcast.com</a>
                        </p>
                    </div>
                </div>
                
                <form 
                    v-if="!status"
                    @submit.prevent="submitForm()">
                    
                    <h4 class="h4 font-bold text-blue-900 mb-4">Reach out to us</h4>
                    
                    <input 
                        v-model="form.firstName"
                        type="text"
                        :placeholder="$t('general.firstName')"
                    />
                    
                    <input 
                        v-model="form.lastName"
                        type="text"
                        :placeholder="$t('general.lastName')"
                    />
                    
                    <input 
                        v-model="form.companyEmail"
                        type="email"
                        :placeholder="$t('general.companyEmail')"
                    />
                    
                    <input 
                        v-model="form.companyName"
                        type="text"
                        :placeholder="$t('general.companyName')"
                    />
                    
                    <input 
                        v-model="form.companyWebsite" 
                        type="text" 
                        :placeholder="$t('general.companyWebsite')" 
                    />

                    <button type="submit" class="bg-yellow-600 px-4 py-3 text-white rounded">
                        Send
                    </button>
                </form>
                <div 
                    v-else
                    class="py-8 text-left">
                    
                    <i class="fas fa-thumbs-up text-yellow-600 text-5xl" />
                </div>
                
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            form: {
                formType: 'companyReachOutToUs',
                firstName: null,
                lastName: null,
                companyEmail: null,
                companyName: null,
                companyWebsite: null,
            },
            status: null,
        }
    },
    
    methods: {
        async submitForm(){
            const response = await this.$axios.post('https://9qqwueeee8.execute-api.eu-west-1.amazonaws.com/prod/form', this.form);
            console.log(response);
            this.status = response;
        }
    },
}
</script>

<style lang="scss" >
</style>
